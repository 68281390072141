import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import {Paper, Grid, Button} from '@material-ui/core';
import { TextField, Typography } from '@material-ui/core';

import {useUserState} from '../../../context/UserContext';
import {useUserListState } from '../../../context/UserListContext';
import { UserProps } from '../../../common/entityUtilities';
// import { UIPropsForNewParentDialog } from '../../../common/entityUtilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

// For future implementation/ reference
interface UserInfoUIProps {
  editOrCreateUser: (user: UserProps) => void;
}
// https://felixgerschau.com/react-typescript-components
//https://www.welcomedeveloper.com/react-typescript-material-design
// For now use props but with knowledge of what is being sent
// this component is not in charge of changing any of the settings
// it passes info to the newparentdialog (change name please?)
export const CurrentUserInfo = (props:UserInfoUIProps):JSX.Element=> {
  
  const user = useUserState();
  const {editOrCreateUser} = props;
  // These value are grabbed from mongodb, if localEmail is not defined
  // then the user is not registered and they shouldn't be here.
  const {firstname, lastname, localEmail} = user;
  const classes = useStyles();

  // for some unknown reason, this component does not do any saving
  // or changing of use values.
  function updateCurrentUser(){
    if(editOrCreateUser){
      // the UI is not editable, and we are assuming that the
      // user that is being displayed is the currently logged in
      // user. 
      editOrCreateUser(user);
    }
  };
  
  return(
    <div>
      <Paper className={classes.paper}>      
        <Grid
          alignItems="center"
          container
          direction="row"
          justify="space-evenly"
        >
          <Grid
            container
            item
            spacing={1}
          >
            <Grid
              alignItems="center"
              item
              sm={2}
              xs={2}
            >
              <Typography align="center">
                Logged in User
              </Typography>
            </Grid>
            <Grid
              item 
              sm={2}
              xs={2}
            >
              <TextField
                label="First Name"
                value={firstname}
              />
            </Grid>
            <Grid
              item
              sm={2}
              xs={2}
            >
              <TextField
                label="Last Name"
                value={lastname}
              />
            </Grid>
            <Grid
              item
              sm={4}
              xs={4}
            >
              <TextField
                inputProps={{readOnly:true}}
                label="Email"
                value={localEmail}
              />
            </Grid> 
            <Grid
              item
              sm={2}
              xs={2}
            >
              <Button
                color="primary"
                onClick={updateCurrentUser}
                variant="outlined"
              >
                Modify User
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};