import React from 'react';

import PropTypes from 'prop-types';
import {
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

  

const AssessmentTableHead = (props)=> {

  const {t, i18n} = useTranslation();

  const columns = [
      {
        id:'date',
        numeric:true,
        disablePadding:false,
        label:t('date',{ns:'asmttablehead'})
      },
      {
        id:'status',
        numeric:false,
        disablePadding: false,
        label: t('status',{ns:'asmttablehead'})
      },
      {
        id:'report',
        numeric:false,
        disablePadding: false,
        label:t('report',{ns:'asmttablehead'})
      } ,
      {
        id:'video',
        numeric:false,
        disablePadding: false,
        label:t('video',{ns:'asmttablehead'})
      },
      {
        id:'restart',
        numeric:false,
        disablePadding: false,
        label:t('restart',{ns:'asmttablehead'})
      }
    ];

    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount
    } = props;
  
    const createSortHandler = property => event => {
      props.onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
        
          {columns.map(
            column => (
              <TableCell
                align={column.numeric ? 'center' : 'left'}
                key={column.id}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}
              >
                <Tooltip
                  enterDelay={300}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  title="Sort"
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  };
  
  AssessmentTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };
  export default AssessmentTableHead;