import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.background.light,
  },
  linkNested: {
    paddingLeft: 0,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.background.light
      // backgroundColor: '#FFFFFF',
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    color: theme.sidebar.color + '99',
    // color: theme.palette.text.secondary + '99',
    transition: theme.transitions.create('color'),
    width: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  linkIconActive: {
    color: theme.sidebar.color,
  },
  linkText: {
    padding: 0,
    color: theme.sidebar.color + 'CC',
    // color: theme.palette.text.secondary + 'CC',
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: '1rem',
    fontFamily:'-apple-system',
    fontWeight: 400,
    lineHeight:1.75
  },
  linkTextActive: {
    // color: theme.palette.primary.main,
    color: theme.sidebar.color,
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    // paddingLeft: theme.spacing(2) + 30,
    paddingLeft: theme.spacing(2),
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: '#D8D8D880',
  },
}));
